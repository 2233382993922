import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  InjectionToken,
  importProvidersFrom,
} from '@angular/core';
import { HashLocationStrategy, Location, LocationStrategy, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, Router, withComponentInputBinding, withHashLocation } from '@angular/router';
// ng-zorro-antd
import { en_US, provideNzI18n } from 'ng-zorro-antd/i18n';
import { NzModalModule } from 'ng-zorro-antd/modal';
// ngrx
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { RouterEffects } from './effects';
import { AuthEffects } from '@app/auth/effects/auth.effects';
import { reducers, metaReducers } from './reducers';
// sentry
import * as Sentry from '@sentry/angular-ivy';
// services
import { httpInterceptorProviders } from '@core/interceptors';

import { routes } from './app.routes';

registerLocaleData(en);

// * 글로벌 로딩이 필요한 API 호출에서 아래 토큰값을 키로 세팅하여 로딩여부를 세팅한다
export const HTTP_HEADER_LOADING = new InjectionToken<string>('httpRequest.headers.loading');
// * 401 체크가 필요없는 API 호출에서 아래 토큰값을 키로 세팅하여 리다이렉트 여부를 세팅한다
export const HTTP_HEADER_NO_401_CHECK = new InjectionToken<string>('httpRequest.headers.no401Redirect');

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding(), withHashLocation()),
    provideStore(reducers, { metaReducers }),
    provideEffects([RouterEffects, AuthEffects]),
    provideRouterStore(),
    provideNzI18n(en_US),
    importProvidersFrom(NzModalModule),
    importProvidersFrom(FormsModule),
    importProvidersFrom(HttpClientModule),
    provideAnimations(),
    { provide: HTTP_HEADER_LOADING, useValue: 'global-loading' },
    { provide: HTTP_HEADER_NO_401_CHECK, useValue: 'no-401-check' },
    httpInterceptorProviders,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
};
