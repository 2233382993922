import { createAction, props, union } from '@ngrx/store';
import { ILoginCondition, ILoginResult } from '../models';

export const login = createAction('[Auth] Login', props<{ condition: ILoginCondition }>());
export const loginSuccess = createAction('[Auth] Login Success', props<{ data: ILoginResult }>());
export const loginFailure = createAction('[Auth] Login Failure', props<{ error: any }>());

export const updateTokens = createAction(
  '[Auth] Update User Tokens',
  props<{ accessToken: string; refreshToken: string }>(),
);

export const logout = createAction('[Auth] Logout');

export const loginRedirect = createAction('[Auth] Login Redirect');

// export type AuthActionsUnion = ReturnType<typeof login>;
const allType = union({
  login,
  loginSuccess,
  loginFailure,
  updateTokens,
  logout,
  loginRedirect,
});
export type AuthActionsUnion = typeof allType;
