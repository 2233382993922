import { Component, ViewEncapsulation, inject } from '@angular/core';
// ng-zorro-antd
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
// models
import { IConfirmModal } from './confirm.model';

@Component({
  selector: 'custom-confirm-modal',
  template: `
    <div class="pop_wrap">
      <div class="pop_inner">
        <div class="pop_top">
          <i class="ico_pop ico_comm">icon</i>
          <strong class="tit_popup">{{ nzModalData.title }}</strong>
        </div>
        <div class="pop_content">
          <p class="txt_pop">{{ nzModalData.content }}</p>
        </div>
        <div class="pop_bottom">
          <button (click)="onConfirm()" type="button" class="btn_close">Yes</button>
          <button (click)="onCancel()" type="button" class="btn_close">No</button>
        </div>
      </div>
    </div>
  `,
  standalone: true,
  encapsulation: ViewEncapsulation.None,
})
export class CustomConfirmModalComponent {
  readonly #modal = inject(NzModalRef<CustomConfirmModalComponent, boolean>);
  readonly nzModalData: IConfirmModal = inject(NZ_MODAL_DATA);

  //#region events
  onConfirm() {
    this.#modal.close(true);
  }
  onCancel() {
    this.#modal.close(false);
  }
  //#endregion
}
