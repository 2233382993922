import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import * as fromAuth from '../../auth/reducers/auth.reducer';
import { AuthActions } from '../actions';

export const authGuard: CanActivateFn = () => {
  const store = inject(Store);
  return store.select(fromAuth.selectLoggedIn).pipe(
    tap((authed) => {
      if (!authed) {
        store.dispatch(AuthActions.loginRedirect());
      }
    }),
    take(1),
  );
};
