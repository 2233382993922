import { Inject, Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, combineLatest, Subject } from 'rxjs';
import { catchError, switchMap, filter, take, map, withLatestFrom, tap, takeUntil } from 'rxjs/operators';
import { isUndefined } from 'lodash-es';
// ngrx
import { Store } from '@ngrx/store';
import * as fromAuth from '@app/auth/reducers/auth.reducer';
import { AuthActions } from '@app/auth/actions';
// services
import { AuthApiService } from '@app/auth/services';
import { HTTP_HEADER_NO_401_CHECK } from '@app/app.config';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  // Token refresh 관련
  isRefreshing: boolean = false;
  $isRefreshed: Subject<boolean> = new Subject<boolean>();
  $unsubscribe = new Subject<void>();

  accessToken$: Observable<string | undefined>;
  refreshToken$: Observable<string | undefined>;
  constructor(
    private store: Store,
    private authApiService: AuthApiService,
    @Inject(HTTP_HEADER_NO_401_CHECK) private no401CheckHeader: string,
  ) {
    this.accessToken$ = this.store.select(fromAuth.selectUserAccessToken);
    this.refreshToken$ = this.store.select(fromAuth.selectUserRefreshToken);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      // ! 401에러 처리
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          // * 로직추가: 401 체크가 필요없는 API 일 경우
          if (!request.headers.has(this.no401CheckHeader)) {
            return this.handle401Error(request, next);
          }
        }
        return throwError(() => error);
      }),
    );
  }

  private handle401Error = (request: HttpRequest<any>, next: HttpHandler) => {
    if (!this.isRefreshing) {
      // 토큰갱신 API 를 호출하고, 토큰을 갱신한다.
      this.isRefreshing = true;
      this.$isRefreshed.next(false);

      return combineLatest([this.accessToken$, this.refreshToken$]).pipe(
        take(1),
        map(([accessToken, refreshToken]) => ({ accessToken, refreshToken })),
        switchMap((condition) => {
          return this.authApiService.refreshToken(condition).pipe(
            tap({
              error: () => {
                // 로그아웃 처리
                // ! 메인스트림이랑 관련이 없는 사이드 이펙트라서 tap() 에서 해당 로직을 실행한다.
                this.store.dispatch(AuthActions.logout());
              },
            }),
            switchMap(({ accessToken, refreshToken }) => {
              this.isRefreshing = false;
              this.$isRefreshed.next(true);
              this.store.dispatch(AuthActions.updateTokens({ accessToken, refreshToken }));
              return next.handle(this.addTokenHeader(request, accessToken));
            }),
            catchError((error) => {
              // * 모든 refresh 관련된 구독을 끊어버린다.
              this.isRefreshing = false;
              this.$isRefreshed.next(false);
              this.$unsubscribe.next();
              this.$unsubscribe.complete();
              if (error instanceof HttpErrorResponse) {
                // Http 에러 일 경우 메세지를 추출한다
                return throwError(() => new Error(error.message));
              } else {
                // 500 에러이고, error 가 string 타입의 에러메세지 일 경우
                return throwError(() => new Error(error));
              }
            }),
          );
        }),
      );
    }

    // accessToken이 갱신 되고 $isRefreshed 값이 true 일 때까지 기다렸다가, 새로운 accessToken으로 next.handler() 실행한다.
    return this.accessToken$.pipe(
      takeUntil(this.$unsubscribe),
      withLatestFrom(this.$isRefreshed),
      filter(([token, isRefreshed]) => isRefreshed === true && !isUndefined(token)),
      switchMap(([token]) => {
        return next.handle(this.addTokenHeader(request, token!));
      }),
    );
  };

  /**
   * HttpRequest.header 에 토큰추가
   * @param request
   * @param token
   * @returns
   */
  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
  }
}
