import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class NoopInterceptor implements HttpInterceptor {
  // * 해당 함수는 나가는 request 및 들어오는 response 를 가로채어 임의의 커스텀 로직을 실행할수 있다.
  // * 그렇다고 해서 나가는 시점에 한번, 들어오는 시점에 한번 호출되는것은 아니다.
  // * 한번만 호출되고, 나가는 시점에서는 request 가공, 들어오는 시점에서는 response 가공을 진행한다.
  // Request 가공은 auth-interceptor 참조
  // Response 가공은 logging-interceptor 참조
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      // ? ev 값이 { type: 0 } 일 경우 ?
      // The 0 event is Sent, which indicates the request has been dispatched to the backend.
      // 0 이벤트가 전송되면 요청이 백엔드로 발송되었음을 나타냅니다.
      // Response events come later, when the request is actually fulfilled.
      // 응답 이벤트는 나중에 요청이 실제로 처리될 때 발생합니다.
      /**
       * 하나의 http 요청을 발생시켰을 경우 실제로 콘솔에 아래와 같이 두번 찍히게 된다.
       * 정상적인 response {type: 0}
       * 정상적인 response HttpResponse { blablablabla... }
       */
      // tap({
      //   next: (ev) => {
      //     console.log('정상적인 response', ev);
      //   },
      //   error: (ev) => {
      //     console.error('에러가 발생한 response', ev);
      //   },
      // }),
      finalize(() => {
        // console.warn(`${req.url}:: Interceptor 최종실행`);
      }),
    );
  }
}
