import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { map, tap } from 'rxjs/operators';

// ngrx
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { routerNavigatedAction } from '@ngrx/router-store';
import { concatLatestFrom } from '@ngrx/operators';
import * as fromRoot from '@app/reducers';

import { environment } from '@env/environment';

@Injectable()
export class RouterEffects {
  domain: 'Wise Convey' | 'E2E';

  updateTitle$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(routerNavigatedAction),
        concatLatestFrom(() => this.store.select(fromRoot.selectRouteData)),
        map(([, data]) => `${this.domain} - ${data['title']}`),
        tap((title) => this.titleService.setTitle(title)),
      );
    },
    {
      dispatch: false,
    },
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private titleService: Title,
  ) {
    switch (environment.domain) {
      case 'wiseconvey':
        this.domain = 'Wise Convey';
        break;
      case 'e2e':
        this.domain = 'E2E';
        break;
      default:
        this.domain = 'Wise Convey';
        break;
    }
  }
}
