import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HTTP_HEADER_LOADING, HTTP_HEADER_NO_401_CHECK } from 'src/app/app.config';

@Injectable({ providedIn: 'root' })
export class ApiRequestService {
  private loadingHeader: string;
  constructor(
    private http: HttpClient,
    @Inject(HTTP_HEADER_LOADING) headerKey: string,
    @Inject(HTTP_HEADER_NO_401_CHECK) private no401CheckHeader: string,
  ) {
    this.loadingHeader = headerKey;
  }

  //#region GET 메소드
  /**
   * GET 호출
   * @param url
   * @param params
   */
  public get(url: string, params?: any): Observable<any> {
    return this.http.get(url, { params: this.makeHttpParams(params) });
  }

  /**
   * 글로벌로딩을 동반한 GET 호출
   * @param url
   * @param params
   * @returns
   */
  public getWithLoading(url: string, params?: any): Observable<any> {
    return this.http.get(url, {
      params: this.makeHttpParams(params),
      headers: this.addGlobalLoadingHeader(),
    });
  }

  /**
   * 401 체크가 필요없는 GET 호출
   * @param url
   * @param params
   * @returns
   */
  public getWithNo401Check(url: string, params?: any): Observable<any> {
    return this.http.get(url, {
      params: this.makeHttpParams(params),
      headers: this.addNo401CheckHeader(),
    });
  }
  //#endregion

  //#region POST 메소드
  /**
   * POST 호출
   * @param url
   * @param params
   */
  post(url: string, params?: any): Observable<any> {
    return this.http.post(url, params);
  }

  /**
   * 글로벌로딩을 동반한 POST 호출
   * @param url
   * @param params
   * @returns
   */
  postWithLoading(url: string, params: any): Observable<any> {
    return this.http.post(url, params, { headers: this.addGlobalLoadingHeader() });
  }

  /**
   * BLOB 타입의 리스판스를 받아오는 POST 호출
   * @param url
   * @param params
   * @returns
   */
  // postBlob(url: string, params?: any): Observable<any> {
  //   return this.http.post(url, params, {
  //     headers: this.addGlobalLoadingHeader({
  //       'Content-Type': 'application/json',
  //       Accept: 'application/json',
  //     }),
  //     responseType: 'blob' as 'json',
  //   });
  // }
  postBlob(url: string, params?: any): Observable<any> {
    return this.http.post<Blob>(url, params, {
      headers: this.addGlobalLoadingHeader(),
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  // RS가 html 형태로 내려오는 경우 RS파싱에러로 추가
  public postText(url: string, params?: any): Observable<any> {
    return this.http.post(url, params, { responseType: 'text' });
  }

  //#endregion

  //#region PUT 메소드
  /**
   * PUT 호출
   * @param url
   * @param params
   */
  public put(url: string, params?: any): Observable<any> {
    return this.http.put(url, params);
  }

  /**
   * 글로벌로딩을 동반한 PUT 호출
   * @param url
   * @param params
   * @returns
   */
  putWithLoading(url: string, params: any): Observable<any> {
    return this.http.put(url, params, { headers: this.addGlobalLoadingHeader() });
  }
  //#endregion

  //#region DELETE 메소드
  /**
   * DELETE 호출
   * @param url 전송될 api
   */
  public delete(url: string): Observable<any> {
    return this.http.delete(url);
  }

  /**
   * 글로벌로딩을 동반한 DELETE 호출
   * @param url
   * @returns
   */
  public deleteWithLoading(url: string): Observable<any> {
    return this.http.delete(url, {
      headers: this.addGlobalLoadingHeader(),
    });
  }
  //#endregion

  /**
   * patch
   * patch api 호출
   * @param url 전송될 api
   * @param params 전송될 params
   */
  public patch(url: string, params?: any): Observable<any> {
    return this.http.patch(url, params);
  }

  /**
   * 글로벌로딩을 동반한 PUT 호출
   * @param url
   * @param params
   * @returns
   */
  patchWithLoading(url: string, params?: any): Observable<any> {
    return this.http.patch(url, params, { headers: this.addGlobalLoadingHeader() });
  }

  /**
   * options.param 세팅
   * @param params Object 혹은 리스트 형식의 파라미터
   */
  private makeHttpParams(params: any): HttpParams | undefined {
    if (params) {
      if (params.constructor === Array) {
        return params.reduce((p, key) => {
          const objKey = Object.keys(key)[0];
          return p.append(objKey, key[objKey]);
        }, new HttpParams());
      } else {
        return Object.getOwnPropertyNames(params).reduce(
          (p, key) => p.append(key, params[key]),
          new HttpParams(),
        );
      }
    } else {
      return undefined;
    }
  }

  /**
   * HttpRequestHeader에 글로벌로딩 flag를 추가한다
   * @param headers
   * @returns
   */
  private addGlobalLoadingHeader(headers = {}) {
    return new HttpHeaders({ ...headers, [this.loadingHeader]: 'on' });
  }

  private addNo401CheckHeader(headers = {}) {
    return new HttpHeaders({ ...headers, [this.no401CheckHeader]: 'on' });
  }
}
