import { isDevMode } from '@angular/core';
import { ActionReducerMap, ActionReducer, MetaReducer, INIT } from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';

// reducers
import * as fromAuth from '@app/auth/reducers/auth.reducer';
// actions
import { AuthActions } from '@app/auth/actions';
// utils
import { StorageHandler } from '@core/utils/storage-handler';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  [fromAuth.featureKey]: fromAuth.State;
  router: fromRouter.RouterReducerState;
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const reducers: ActionReducerMap<State> = {
  [fromAuth.featureKey]: fromAuth.reducer,
  router: fromRouter.routerReducer,
};

//#region metaReducers
// * session storage meta reducer
export function authFromSessionStorage(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => {
    const newState = reducer(state, action);
    if (action.type === INIT) {
      // ? set session storage
      const authKey = fromAuth.featureKey;
      const storageValue = StorageHandler.GetSavedSessionStorage();
      return Object.assign({ ...newState }, { [authKey]: storageValue[authKey] });
    }
    if (action.type === AuthActions.loginSuccess.type || action.type === AuthActions.updateTokens.type) {
      // ? get session storage
      StorageHandler.SetItems('sessionStorage', newState);
    }
    return newState;
  };
}
// * console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => {
    const result = reducer(state, action);
    console.groupCollapsed(action.type);
    console.log('prev state', state);
    console.log('action', action);
    console.log('next state', result);
    console.groupEnd();
    return result;
  };
}

// * logout meta reducer
export function logoutMetaReducer(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => {
    if (action != null && action.type === AuthActions.logout.type) {
      // if logout success, reset the state to undefined, clear the session storage
      StorageHandler.Clear('sessionStorage');
      return reducer(undefined, { type: INIT });
    }
    return reducer(state, action);
  };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = isDevMode()
  ? [logger, authFromSessionStorage, logoutMetaReducer]
  : [authFromSessionStorage, logoutMetaReducer];
//#endregion

/**
 * Router Selectors
 */
export const { selectRouteData, selectUrl } = fromRouter.getRouterSelectors();
