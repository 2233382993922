import { Injectable } from '@angular/core';
// services
import { AppService } from '@app/app.service';

@Injectable({ providedIn: 'root' })
export class ErrorService {
  constructor(private appService: AppService) {}

  createAlert(error: any, customMessage: string) {
    this.appService.createAlert({
      title: 'Error',
      content: error?.message || customMessage,
    });
  }
}
