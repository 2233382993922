import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
// services
import { ApiRequestService } from '@core/services/api/api-request.service';
// models
import { ApiPath } from '../models';

@Injectable({ providedIn: 'root' })
export class AuthApiService {
  constructor(private apiService: ApiRequestService) {}

  login(params: any): Observable<any> {
    return this.apiService.getWithLoading(ApiPath.signin, params).pipe(
      switchMap((res) => {
        if (res.code === '200') {
          return of(res.data);
        } else {
          return throwError(() => res);
        }
      }),
    );
  }

  refreshToken(params: any): Observable<any> {
    return this.apiService.put(ApiPath.refreshToken, params).pipe(
      switchMap((res) => {
        if (res.code === '200') {
          return of(res.data);
        } else {
          return throwError(() => res.message);
        }
      }),
    );
  }

  getUserInfo(): Observable<any> {
    return this.apiService.get(ApiPath.getAuthUsers).pipe(
      switchMap((res) => {
        if (res.code === '200') {
          return of(res.data);
        } else {
          return throwError(() => res.message);
        }
      }),
    );
  }
}
