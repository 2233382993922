import { environment } from '@env/environment';

export const ApiPath = {
  signin: `${environment.apiUrl}/api/users/signin`,
  refreshToken: `${environment.apiUrl}/api/auth/tokens`,
  getAuthUsers: `${environment.apiUrl}/api/auth/users`,
};

//#region 로그인 API 관련
export interface ILoginCondition {
  email: string;
  password: string;
}
export interface ILoginResult {
  userSeq: number;
  name: string;
  cellphone: string;
  directCall: string;
  email: string;
  accessToken: string;
  refreshToken: string;
  branchSeq: number;
}
//#endregion
