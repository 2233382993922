import { Injectable, ViewContainerRef } from '@angular/core';
// ng-zorro-antd
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
// models
import { IAlertModal } from '@shared/components/alert/alert.model';
import { IConfirmModal } from '@shared/components/confirm/confirm.model';
// components
import { CustomErrorAlertComponent } from '@shared/components/alert';
import { CustomConfirmModalComponent } from '@shared/components/confirm';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppService {
  //#region states
  appViewContainerRef: ViewContainerRef | undefined;
  private $confirmTrigger = new Subject<void>();
  confirmTrigger$ = this.$confirmTrigger.asObservable();
  //#endregion

  constructor(private modal: NzModalService) {}

  //#region methods
  setAppViewContainer(viewContainerRef: ViewContainerRef): void {
    this.appViewContainerRef = viewContainerRef;
  }

  createAlert(modalData: IAlertModal): void {
    this.modal!.create<CustomErrorAlertComponent, IAlertModal>({
      nzTitle: '',
      nzContent: CustomErrorAlertComponent,
      nzViewContainerRef: this.appViewContainerRef,
      nzData: modalData,
      nzFooter: null,
    });
  }

  createConfirm(modalData: IConfirmModal): NzModalRef<CustomConfirmModalComponent, boolean> {
    return this.modal!.create<CustomConfirmModalComponent, IConfirmModal, boolean>({
      nzTitle: '',
      nzContent: CustomConfirmModalComponent,
      nzViewContainerRef: this.appViewContainerRef,
      nzData: modalData,
      nzFooter: null,
    });
  }
  //#endregion
}
