/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';

// interceptors
import { NoopInterceptor } from './noop-interceptor';
import { LoadingInterceptor } from './loading-interceptor';
import { AuthInterceptor } from './auth-interceptor';
// import { LoggingInterceptor } from './logging-interceptor';
import { ErrorInterceptor } from './error-interceptor';
import { RefreshTokenInterceptor } from './refresh-token-interceptor';

/** Http interceptor providers in outside-in order */
// * Interceptor 실행은 아래의 배열에 정의된 순서를 따른다
// * Request 가공은 Noop -> Auth -> Logging -> ..... 순으로
// * Response 가공은 Logging -> Auth -> Noop 순으로 진행이 된다
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: NoopInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  // { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
];
