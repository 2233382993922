import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { AuthActions } from '../actions';

// services
import { ErrorService } from '@core/services/error/error.service';
import { AuthApiService } from '../services';
// models
import { ILoginCondition } from '../models';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions<AuthActions.AuthActionsUnion>,
    private authApiService: AuthApiService,
    private router: Router,
    private errorService: ErrorService,
  ) {}

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.login),
      map((action) => action.condition),
      exhaustMap((auth: ILoginCondition) =>
        this.authApiService.login(auth).pipe(
          map((user) => AuthActions.loginSuccess({ data: user })),
          catchError((error) => {
            this.errorService.createAlert(error, 'Failed to login');
            return EMPTY;
          }),
        ),
      ),
    );
  });

  loginSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        tap(() => this.router.navigate(['/'], { replaceUrl: true })),
      );
    },
    { dispatch: false },
  );

  loginRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.loginRedirect.type, AuthActions.logout.type),
        tap(() => {
          this.router.navigate(['/login'], { replaceUrl: true });
        }),
      );
    },
    { dispatch: false },
  );

  // 로그아웃 확인창 띄우기
  // @Effect()
  // logoutConfirmation$ = this.actions$.pipe(
  //   ofType(AuthActions.logoutConfirmation.type),
  //   exhaustMap(() => {
  //     const dialogRef = this.dialog.open<LogoutConfirmationDialogComponent, undefined, boolean>(
  //       LogoutConfirmationDialogComponent,
  //     );

  //     return dialogRef.afterClosed();
  //   }),
  //   map((result) => (result ? AuthActions.logout() : AuthActions.logoutConfirmationDismiss())),
  // );
}
