import { Component, ViewEncapsulation, inject } from '@angular/core';
// ng-zorro-antd
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
// models
import { IAlertModal } from './alert.model';

@Component({
  selector: 'custom-error-alert',
  template: `
    <div class="pop_wrap">
      <div class="pop_inner">
        <div class="pop_top">
          <i class="ico_pop ico_comm">icon</i>
          <strong class="tit_popup">{{ nzModalData.title }}</strong>
        </div>
        <div class="pop_content">
          <p class="txt_pop">{{ nzModalData.content }}</p>
        </div>
        <div class="pop_bottom">
          <button (click)="destroyModal()" type="button" class="btn_close">Close</button>
        </div>
      </div>
    </div>
  `,
  standalone: true,
  encapsulation: ViewEncapsulation.None,
})
export class CustomErrorAlertComponent {
  readonly #modal = inject(NzModalRef);
  readonly nzModalData: IAlertModal = inject(NZ_MODAL_DATA);

  destroyModal(): void {
    this.#modal.destroy({ data: 'this the result data' });
  }
}
