import { Action, createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthActions } from '../actions';
import { assign, isNil } from 'lodash-es';
import { ILoginResult } from '../models';

export const featureKey = 'auth';

export interface State {
  userInfo: ILoginResult | null;
}

export const initialState: State = {
  userInfo: null,
};

export function reducer(state = initialState, action: Action): State {
  const specificAction = action as AuthActions.AuthActionsUnion;

  switch (specificAction.type) {
    case AuthActions.loginSuccess.type: {
      return {
        ...state,
        userInfo: specificAction.data,
      };
    }
    case AuthActions.logout.type: {
      return initialState;
    }
    case AuthActions.updateTokens.type: {
      return {
        ...state,
        userInfo: assign({}, state.userInfo, {
          accessToken: specificAction.accessToken,
          refreshToken: specificAction.refreshToken,
        }),
      };
    }

    default: {
      return state;
    }
  }
}

export const getUserInfo = (state: State) => state.userInfo;
//#region selectors
// * Selectors
export const selectAuthState = createFeatureSelector<State>(featureKey);

export const selectUserInfo = createSelector(selectAuthState, getUserInfo);
export const selectUserSeq = createSelector(selectUserInfo, (user) => user?.userSeq);
export const selectUserName = createSelector(selectUserInfo, (user) => user?.name);
export const selectUserCellPhone = createSelector(selectUserInfo, (user) => user?.directCall);
export const selectUserDirectCall = createSelector(selectUserInfo, (user) => user?.directCall);
export const selectUserEmail = createSelector(selectUserInfo, (user) => user?.email);
export const selectBranchSeq = createSelector(selectUserInfo, (user) => user?.branchSeq);
export const selectIsMasterBranch = createSelector(selectUserInfo, (user) => user?.branchSeq === 1);
export const selectUserAccessToken = createSelector(selectUserInfo, (user) => user?.accessToken);
export const selectUserRefreshToken = createSelector(selectUserInfo, (user) => user?.refreshToken);
export const selectLoggedIn = createSelector(selectUserInfo, (user) => !isNil(user));
//#endregion
