import { Routes } from '@angular/router';
// guard
import { authGuard } from '@app/auth/guard/auth.guard';

export const routes: Routes = [
  { path: '', redirectTo: '/data-management', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('src/app/auth/auth.module').then((m) => m.AuthModule),
    data: { title: 'Login' },
  },
  {
    path: 'terms-of-service',
    loadChildren: () =>
      import('./routes/terms-of-service/terms-of-service.module').then((m) => m.TermsOfServiceModule),
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./routes/privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyModule),
  },
  {
    path: 'main',
    loadChildren: () => import('src/app/routes/main/main.module').then((m) => m.MainModule),
    canActivate: [authGuard],
    data: { title: 'Main' },
  },
  // * AI Scanning 페이지
  {
    path: 'ai-scanning',
    loadChildren: () => import('src/app/routes/ai-scanning/ai-scanning.module').then((m) => m.AiScanningModule),
    canActivate: [authGuard],
    data: { title: 'AI Scanning' },
  },
  // * Export to Excel 페이지
  {
    path: 'export-excel',
    loadChildren: () =>
      import('src/app/routes/export-excel/export-excel.module').then((m) => m.ExportExcelModule),
    canActivate: [authGuard],
    data: { title: 'Export to Excel' },
  },
  // * Cargo Tracking 페이지
  {
    path: 'cargo-tracking',
    loadChildren: () => import('src/app/routes/tracking/tracking.module').then((m) => m.TrackingModule),
    canActivate: [authGuard],
    data: { title: 'Cargo Tracking' },
  },
  // * Data Management 페이지
  {
    path: 'data-management',
    loadChildren: () =>
      import('src/app/routes/data-management/data-management.routes').then((m) => m.DATA_MANAGEMENT_ROUTES),
    canActivate: [authGuard],
  },
  // * Template Setting 페이지
  {
    path: 'template-setting/:templateSeq',
    loadChildren: () =>
      import('src/app/routes/template-setting/template-setting.routes').then((m) => m.TEMPLATE_SETTING_ROUTES),
    canActivate: [authGuard],
  },
  // * Link Editor 페이지
  {
    path: 'link-editor',
    loadChildren: () => import('src/app/routes/link-editor/link-editor.routes').then((m) => m.LINK_EDITOR_ROUTES),
    canActivate: [authGuard],
  },
  // * Warehouse Management(WMS) 페이지
  {
    path: 'warehouse-management',
    loadChildren: () =>
      import('src/app/routes/warehouse-management/warehouse-management.routes').then(
        (m) => m.WAREHOUSE_MANAGEMENT_ROUTES,
      ),
    canActivate: [authGuard],
  },
  // * Inventory Management 페이지
  {
    path: 'inventory-management',
    loadChildren: () =>
      import('src/app/routes/inventory-management/inventory-management.routes').then(
        (m) => m.INVENTORY_MANAGEMENT_ROUTES,
      ),
    canActivate: [authGuard],
  },

  // ?===============================================================================
  {
    path: 'showcase',
    loadChildren: () => import('src/app/routes/showcase/showcase.module').then((m) => m.ShowcaseModule),
    canActivate: [authGuard],
    data: { title: 'Showcase' },
  },

  {
    path: '**',
    loadComponent: () =>
      import('src/app/shared/components/not-found/not-found.component').then((c) => c.NotFoundPageComponent),
  },
];
