import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
// services
import { AppService } from '@app/app.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private appService: AppService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        error: (error) => {
          // 401 아닐경우 에러처리, 401은 refresh token interceptor에서 처리
          if (error instanceof HttpErrorResponse && error.status !== 401) {
            this.appService.createAlert({
              title: 'Error',
              content: error?.message || 'Failed to http request',
            });
          }
        },
      }),
    );
  }
}
