import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoadingService } from '@core/services/loading/loading.service';
import { HTTP_HEADER_LOADING } from '@app/app.config';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  loadingHeader: string;
  constructor(
    private loadingService: LoadingService,
    @Inject(HTTP_HEADER_LOADING) headerKey: string,
  ) {
    this.loadingHeader = headerKey;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.has(this.loadingHeader)) {
      this.loadingService.setLoading(true, request.url);
    }
    return next.handle(request).pipe(
      tap({
        next: (ev) => {
          if (ev instanceof HttpResponse) {
            this.loadingService.setLoading(false, request.url);
          }
        },
        error: () => {
          this.loadingService.setLoading(false, request.url);
        },
      }),
    );
  }
}
