import { Injectable } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';

export interface ILoginForm {
  email: FormControl<string>;
  password: FormControl<string>;
}

@Injectable({ providedIn: 'root' })
export class AuthDataService {
  constructor(private fb: FormBuilder) {}

  // generateLoginForm = () => {
  //   return new FormGroup<ILoginForm>({
  //     email: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
  //     password: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
  //   });
  // };

  generateSignInForm = (): FormGroup<ILoginForm> =>
    this.fb.group<ILoginForm>({
      email: this.fb.nonNullable.control('', [Validators.required]),
      password: this.fb.nonNullable.control('', [Validators.required]),
    });
}
